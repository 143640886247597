// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import generalReducer from './general'
import usersReducer from './views/users.js'
import translationReducer from './views/translation'
import minglingEventReducer from './views/minglingEvent'
import articleReducer from './views/article'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  generalReducer,
  usersReducer,
  translationReducer,
  minglingEventReducer,
  articleReducer
})

export default rootReducer
