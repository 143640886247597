import types from "../../../utility/types"

const initialState = {
    articles: [],
    currArticles: [],
    currArticle: {},
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.article.UPDATE_STATES:
            return { ...state, ...action.values }
        case types.article.FILTER_ARTICLES:
            return { ...state, currArticles: action.values }
        case types.article.FETCH_ARTICLES:
            return { ...state, articles: action.values.data, currArticles: action.values.data, total: action.values.total >= 0 ? action.values.total : state.total }
        case types.article.SET_CURR_ARTICLE:
            return { ...state, currArticle: action.values }
        case types.article.RESET_CURR_ARTICLE:
            return { ...state, currArticle: {} }
        default:
            return state
    }
}