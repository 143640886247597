import types from "../../../utility/types"

const initialState = {
    allTranslations: [],
    translations: [],
    namespace: [],
    currTranslation: {},
    currNamespace: '',
    searchTerm: '',
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.translation.UPDATE_STATES:
            return {  ...state, ...action.values }
        case types.translation.GET_TRANSLATIONS:
            return { ...state, allTranslations: action.values, translations: action.values, total: action.values.length }
        case types.translation.SEARCH_TRANSLATIONS:
            return { ...state, translations: action.values, total: action.values.length }
        case types.translation.SET_CURR_TRANSLATION:
            return { ...state, currTranslation: action.values }
        default:
            return state
    }
}