import types from "../../../utility/types"

const initialState = {
    minglingEvents: [],
    currMinglingEvents: [],
    currMinglingEvent: {},
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.minglingEvent.UPDATE_STATES:
            return { ...state, ...action.values }
        case types.minglingEvent.FILTER_MINGLING_EVENTS:
            return { ...state, currMinglingEvents: action.values }
        case types.minglingEvent.FETCH_MINGLING_EVENTS:
            return { ...state, minglingEvents: action.values.data, currMinglingEvents: action.values.data, total: action.values.total >= 0 ? action.values.total : state.total }
        case types.minglingEvent.SET_CURR_MINGLING_EVENT:
            return { ...state, currMinglingEvent: action.values }
        case types.minglingEvent.RESET_CURR_MINGLING_EVENT:
            return { ...state, currMinglingEvent: {} }
        default:
            return state
    }
}