const users = {
    UPDATE_STATES: 'users/UPDATE_STATES',
    FETCH_DB_USERS: 'users/FETCH_DB_USERS'
}

const general = {
    UPDATE_STATES: 'general/UPDATE_STATES',
    TOGGLE_LOADING: 'general/TOGGLE_LOADING',
    LOGIN: 'general/LOGIN',
    LOGOUT: 'general/LOGOUT',
    TOGGLE_VALIDATING_ADMIN: 'general/TOGGLE_VALIDATING_ADMIN'
}

const translation = {
    UPDATE_STATES: 'translation/UPDATE_STATES',
    GET_TRANSLATIONS: 'transation/GET_TRANSLATIONS',
    SEARCH_TRANSLATIONS: 'translation/SEARCH_TRANSLATIONS',
    SET_CURR_TRANSLATION: 'translation/SET_CURR_TRANSLATION'
}

const minglingEvent = {
    UPDATE_STATES: 'minglingEvent/UPDATE_STATES',
    FETCH_MINGLING_EVENTS: 'minglingEvent/FETCH_MINGLING_EVENTS',
    FILTER_MINGLING_EVENTS: 'minglingEvent/FILTER_MINGLING_EVENTS',
    SET_CURR_MINGLING_EVENT: 'minglingEvent/SET_CURR_MINGLING_EVENT',
    RESET_CURR_MINGLING_EVENT: 'minglingEvent/RESET_CURR_MINGLING_EVENT'
}

const article = {
    UPDATE_STATES: 'article/UPDATE_STATES',
    FETCH_ARTICLES: 'article/FETCH_ARTICLES',
    FILTER_ARTICLES: 'article/FILTER_ARTICLES',
    SET_CURR_ARTICLE: 'article/SET_CURR_ARTICLE',
    RESET_CURR_ARTICLE: 'article/RESET_CURR_ARTICLE'
}

export default {
    general,
    users,
    translation,
    minglingEvent,
    article
}