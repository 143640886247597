import firebase from '../../../firebase'
import types from '../../../utility/types'
import { getAllUsersAPI, getPendingUsersAPI, approveRegistrationAPI, updateUserStatusAPI } from '../../../api/users-api'

const initialState = {
    dbUsersData: [],
    authUsersData: [],
    currAuthUserData: {},
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.users.UPDATE_STATES:
            return { ...state, ...action.values }
        case types.users.FETCH_DB_USERS:
            return { ...state, total: action.values.total === -1 ? state.total : action.values.total, dbUsersData: action.values.data }
        default:
            return state
    }
}

export function fetchPendingUsersData() {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: types.general.TOGGLE_LOADING })

            const data = await getPendingUsersAPI()
            console.log(data)

            return dispatch({
                type: types.users.UPDATE_STATES,
                values: {
                    dbUsersData: data
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            dispatch({ type: types.general.TOGGLE_LOADING })
        }
    }
}

export function fetchUsersData({
    page = 1,
    perPage = 10,
    textQuery = null
} = {}) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: types.general.TOGGLE_LOADING })

            const data = await getAllUsersAPI(page, perPage, textQuery)
            console.log(data.data.data, data.data.total)

            dispatch({
                type: types.users.FETCH_DB_USERS,
                values: { ...data.data }
            })

            return data.data
        } catch (error) {
            console.log(error)
        } finally {
            dispatch({ type: types.general.TOGGLE_LOADING })
        }
    }
}

export function updateUserStatus({
    email = "",
    status = "",
    block_reason = null,
    remarks = ""
}) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: types.general.TOGGLE_LOADING })

            const result = await updateUserStatusAPI(email, status, block_reason, remarks)
            console.log(result)

            window.location.reload()
            return result
        } catch (error) {
            console.trace(error)
        } finally {
            dispatch({ type: types.general.TOGGLE_LOADING })
        }
    }
}

export function approveRegistration({
    uid
}) {
    return async (dispatch, useState) => {
        try {
            dispatch({ type: types.general.TOGGLE_LOADING })

            const result = await approveRegistrationAPI(uid)
            console.log(result)

            alert('Approved')
            window.location.reload()
            return result
        } catch (error) {
            console.trace(error)
        } finally {
            dispatch({ type: types.general.TOGGLE_LOADING })
        }
    }
}

//
// NOT USED
//

export function fetchOneUsersDataForAdmin() {
    return async (dispatch, getState) => {
        try {
            const getOneUser = firebase.functions().httpsCallable('getOneUser')
            const result = await getOneUser()

            return dispatch({
                type: types.users.UPDATE_STATES,
                values: {
                    currAuthUserData: result
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateAuthUser({
    email = "",
    updateBody = {}
}) {
    return async (dispatch, useState) => {
        try {
            const updateUser = firebase.functions().httpsCallable('updateUser')
            const result = await updateUser({
                email,
                ...updateBody
            })
            return result
        } catch (error) {
            console.trace(error)
        }
    }
}

//
// DEPRECATED
//

export function updateUser({
    userId,
    updateBody
}) {
    return async (dispatch, getState) => {
        try {
            const usersDoc = usersRef.doc(userId)
            usersDoc.set(updateBody, { merge: true })
        } catch (error) {
            console.log(error)
        }
    }
}

export function addAuthenticatedUser({
    user
}) {
    return (dispatch, getState) => {
        try {
            let result

            firebase.default.auth().createUserWithEmailAndPassword(
                user.email,
                user.password)
                .then(async (userCredential) => {
                    result = userCredential.user
                })
                .catch((error) => {
                    const errorCode = error.code
                    const errorMessage = error.message
                })

            return result
        } catch (error) {
            console.log(error)
        }
    }
}

export function fetchUsersDataForAdmin() {
    return async (dispatch, getState) => {
        try {
            const data = []
            const getAllUsers = firebase.functions().httpsCallable('getAllUsers')
            const result = await getAllUsers()
            console.log(result)
            result.data.forEach(item => {
                data.push({
                    email: item.email,
                    id: item.uid
                })
            })
            console.log(data)

            return dispatch({
                type: types.users.UPDATE_STATES,
                values: {
                    authUsersData: data
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
}