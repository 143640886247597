import firebase from "firebase/app"
import "firebase/storage"
// import "firebase/firestore"
import "firebase/auth"
import "firebase/functions"
// import "firebase/app-check"
import "firebase/performance"
import config, { ENV } from "./env"

if (!firebase.apps.length) firebase.initializeApp(config.projConfig)

if (ENV === 'local') {
    //---- ports are configured at firebase.json
    //---- emulator UI is on localhost:4000
    // firebase.auth().useEmulator('localhost', 9099)
    firebase.functions().useEmulator('localhost', 5001)
    // firebase.firestore().useEmulator('localhost', 8080)
}

const firebaseStorage = firebase.storage()
// const fireStore = firebase.firestore()
const firebaseAuth = firebase.auth()
// const firebaseAppCheck = firebase.appCheck()
const firebasePerf = firebase.performance()

export default firebase

export { firebaseStorage, /* fireStore,  */firebaseAuth/* , firebaseAppCheck */, firebasePerf }

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
/* firebaseAppCheck.activate(
    '6Ldbh_MbAAAAAG_WsuZEpTxgGvuNPiahCXh8BzRa',

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    true) */
