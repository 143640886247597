import types from '../../utility/types'

const initialState = {
    isLoading: false,
    isLoggedIn: null,
    isValidatingAdmin: false,
    adminDoc: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.general.UPDATE_STATES:
            return {  ...state, ...action.values }
        case types.general.TOGGLE_LOADING:
            return { ...state, isLoading: !state.isLoading }
        case types.general.TOGGLE_VALIDATING_ADMIN:
            return { ...state, isValidatingAdmin: !state.isValidatingAdmin }
        case types.general.LOGIN:
            return { ...state, adminDoc: action.values, isLoggedIn: true }
        case types.general.LOGOUT:
            return { ...state, adminDoc: {}, isLoggedIn: false }
        default:
            return state
    }
}