import firebase from "../firebase"

export const getAllUsersAPI = async (page, perPage, textQuery) => {
    try {
        console.log(`====> getAllUsersAPI`)
        const getAllUsers = firebase.functions().httpsCallable('getAllUsers')
        const data = await getAllUsers({
            page,
            perPage,
            textQuery
        })
        console.log(`====> getAllUsersAPI: ${JSON.stringify(data)}`)

        // TODO
        // if (!result || result?.message === "") return {}

        return data.data

        // TODO
    } catch (err) {
        console.error(err)
        return {}
    }
}

export const getPendingUsersAPI = async () => {
    try {
        console.log(`====> getPendingUsersAPI`)
        const getPendingUsers = firebase.functions().httpsCallable('getPendingUsers')
        const data = await getPendingUsers()
        console.log(`====> getPendingUsersAPI: ${JSON.stringify(data)}`)

        return data.data
        
    } catch (error) {
        console.error(error)
    }
}

export const updateUserStatusAPI = async (email, status, block_reason, remarks) => {
    try {
        console.log(`====> updateUserStatusAPI`)
        const updateUserStatus = firebase.functions().httpsCallable('updateUserStatus')
        const data = await updateUserStatus({
            email,
            status,
            block_reason,
            remarks
        })
        console.log(`====> updateUserStatusAPI: ${JSON.stringify(data)}`)

        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const approveRegistrationAPI = async (uid) => {
    console.log(`====> approveRegistrationAPI`)
    const approveRegistration = firebase.functions().httpsCallable('approveRegistration')
    const data = await approveRegistration({
        user_id: uid
    })
    console.log(`====> approveRegistrationAPI: ${JSON.stringify(data)}`)

    return data.data
}